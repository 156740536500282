<template>
    <layout-div>
        <div class="content-container">
            <NavBarComponent @logout="logoutAction" />
        </div>
        <br>
        <div class="flex justify-center mt-5">
            <button class="button" @click="showAbsenceModal = true">
                Abwesenheit melden
            </button>
        </div>

        <div class="mt-10 flex justify-center">
            <div class="w-full max-w-2xl">
                <h2 class="text-xl font-bold mb-4 text-center">Saldo Urlaub</h2>
                <div class="overflow-x-auto">
                    <table class="min-w-full bg-white rounded-lg shadow-lg w-full">
                        <thead>
                            <tr class="bg-gray-50">
                                <th class="py-2 px-4 text-center">Saldo Urlaub</th>
                                <th class="py-2 px-4 text-center">Saldo Pflegeurlaub</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b hover:bg-gray-100">
                                <td class="py-2 px-4 text-center font-bold">{{ this.user.vacation }}</td>
                                <td class="py-2 px-4 text-center font-bold">{{ this.user.care_vacation }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content-container mt-6">
            <div v-if="userAbsences.length > 0" class="mt-10 px-4">
                <h2 class="text-xl font-bold mb-4">Meine Abwesenheiten</h2>
                <div class="legend">
                    <div class="legend-item">
                        <span class="legend-color bg-blue-300"></span>
                        <span>Abw. noch nicht freigegeben</span>
                    </div>
                    <div class="legend-item">
                        <span class="legend-color bg-red-300"></span>
                        <span>Dokumente nicht hochgeladen</span>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <table class="min-w-full bg-white rounded-lg shadow-lg w-full">
                        <tbody>
                            <tr 
                                v-for="(absence, index) in userAbsences" 
                                :key="index" 
                                :class="{
                                'border-b cursor-pointer': true,
                                'bg-red-300': absence.admin_absence_confirmed === true && (absence.sickConfirmationFileId === null || absence.healthConfirmationFileId === null), // Rot, wenn admin_absence_confirmed = true, aber nicht alle Dokumente hochgeladen
                                'bg-blue-300': absence.admin_absence_confirmed === false, // Custom-Hintergrund, wenn admin_absence_confirmed = false
                                'bg-gray-100': index % 2 === 0 && absence.admin_absence_confirmed === true && absence.sickConfirmationFileId !== null && absence.healthConfirmationFileId !== null,  // Hellgrau für gerade Zeilen, wenn alles okay ist
                                'bg-white': index % 2 !== 0 && absence.admin_absence_confirmed === true && absence.sickConfirmationFileId !== null && absence.healthConfirmationFileId !== null // Weiß für ungerade Zeilen, wenn alles okay ist
                                }"
                                @click="openUploadModal(absence)"
                            >
                                <td class="py-2 px-4"><b>ID: {{ absence.id }}</b></td>
                                <td class="py-2 px-4">
                                <span v-if="absence.sickConfirmationFileId !== null && absence.sickConfirmationFileId !== undefined" class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-sm mb-1">
                                    Abwesend.Doc <i class="bi bi-check-circle"></i>
                                </span>
                                <span v-if="absence.healthConfirmationFileId !== null && absence.healthConfirmationFileId !== undefined" class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-sm mb-1">
                                    Anwesend.Doc <i class="bi bi-check-circle"></i>
                                </span>
                                <span v-if="absence.sickConfirmationFileId === null && absence.healthConfirmationFileId === null" class="inline-block bg-red-500 text-white px-2 py-1 rounded-full text-sm">
                                    Dokumente Fehlen
                                </span>
                                </td>
                                <td class="py-2 px-4">{{ absence.absence_type }}</td>
                                <td class="py-2 px-4">{{ formatDate(absence.absence_start) }} - {{ absence.absence_time_start }}</td>
                                <td class="py-2 px-4">{{ formatDate(absence.absence_end) }} - {{ absence.absence_time_end }}</td>
                                <td class="py-2 px-4">{{ absence.comment }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content-container mt-6">
            <div v-if="absencesChildren.length > 0" class="mt-10 px-4">
                <h2 class="text-xl font-bold mb-4">Betreuungs-Kind Abwesenheit</h2>
                <div class="overflow-x-auto">
                    <table class="min-w-full bg-white rounded-lg shadow-lg w-full">
                        <tbody>
                            <tr v-for="(absence, index) in absencesChildren" :key="index" class="border-b hover:bg-gray-100">
                                <td class="py-2 px-4">{{ getChildName(absence.fk_children_id) }}</td>
                                <td class="py-2 px-4">Abwesend ab {{ formatDate(absence.absence_start) }}</td>
                                <td class="py-2 px-4">{{ absence.comment }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div v-if="showAbsenceModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white rounded-lg shadow-xl w-96 max-h-screen overflow-y-auto">
                <div class="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h5 class="text-lg font-bold">Abwesenheit melden</h5>
                    <button @click="showAbsenceModal = false" class="text-gray-600 hover:text-gray-900 text-xl font-bold">&times;</button>
                </div>
                <div class="p-6">
                    <div class="mb-4">
                        <label for="absenceType" class="block text-sm font-medium text-gray-700">Wer ist abwesend?</label>
                        <select id="absenceType" v-model="absenceType" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Bitte wählen</option>
                            <option value="caregiver">Mich Abwesend melden</option>
                            <option value="child">Kind Abwesend</option>
                        </select>
                    </div>

                    <div class="mb-4" v-if="absenceType === 'child'">
                        <label for="childSelect" class="block text-sm font-medium text-gray-700">Kind auswählen</label>
                        <select id="childSelect" v-model="selectedChild" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Bitte Kind auswählen</option>
                            <option v-for="(child, index) in children" :key="index" :value="child">{{ child }}</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <label for="absenceDateStart" class="block text-sm font-medium text-gray-700">Abwesenheit-Start</label>
                        <input type="date" v-model="absenceDateStart" id="absenceDateStart" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                    </div>

                    <template v-if="absenceType !== 'child'">
                        <div class="mb-4">
                            <label for="absenceDateEnd" class="block text-sm font-medium text-gray-700">Abwesenheit-Ende</label>
                            <input type="date" v-model="absenceDateEnd" id="absenceDateEnd" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="mb-4 flex items-center">
                            <input type="checkbox" id="allDay" v-model="allDay" @change="setAllDayTimes" class="mr-2">
                            <label for="allDay" class="text-sm font-medium text-gray-700">Ganztägig</label>
                        </div>

                        <div class="mb-4" v-if="!allDay">
                            <label for="startTime" class="block text-sm font-medium text-gray-700">Uhrzeit-Start</label>
                            <select id="startTime" v-model="startTime" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                <option v-for="time in timeOptions" :key="time" :value="time">{{ convertToHHMM(time) }}</option>
                            </select>
                        </div>

                        <div class="mb-4" v-if="!allDay">
                            <label for="endTime" class="block text-sm font-medium text-gray-700">Uhrzeit-Ende</label>
                            <select id="endTime" v-model="endTime" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                <option v-for="time in timeOptions" :key="time" :value="time">{{ convertToHHMM(time) }}</option>
                            </select>
                        </div>

                        <div class="mb-4">
                            <label for="absenceReason" class="block text-sm font-medium text-gray-700">Grund der Abwesenheit</label>
                            <select id="absenceReason" v-model="absenceReason" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                <option value="Krankenstand">Krankenstand</option>
                                <option value="Pflegeurlaub">Pflegeurlaub</option>
                                <option value="Arzttermin">Arzttermin</option>
                                <option value="Sonderurlaub">Sonderurlaub</option>
                                <option value="Zeitausgleich">Zeitausgleich</option>
                                <option value="Urlaub">Urlaub</option>
                            </select>
                        </div>
                    </template>

                    <div class="mb-4">
                        <label for="comment" class="block text-sm font-medium text-gray-700">Kommentar</label>
                        <textarea id="comment" v-model="comment" rows="3" placeholder="Optional: Kommentar hinzufügen" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"></textarea>
                    </div>
                </div>
                <div class="px-6 py-3 border-t border-gray-200 flex justify-end">
                    <button class="button mr-2" @click="showAbsenceModal = false">Schließen</button>
                    <button class="button" @click="reportAbsence" :disabled="!isValidForm">Abwesenheit melden</button>
                </div>
            </div>
        </div>

        <div v-if="showUploadModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white rounded-lg shadow-xl w-96 max-h-screen overflow-y-auto">
                <div class="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h5 class="text-lg font-bold">Bestätigung Hochladen</h5>
                    <button @click="closeUploadModal" class="text-gray-600 hover:text-gray-900 text-xl font-bold">&times;</button>
                </div>
                <div class="p-6">
                    <div class="mb-4">
                        <label for="documentType" class="block text-sm font-medium text-gray-700">Dokumenttyp</label>
                        <select id="documentType" v-model="selectedDocumentType" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Bitte auswählen</option>
                            <option v-if="selectedAbsence && selectedAbsence.sickConfirmationFileId === null" value="CAREGIVER_SICK_DOC">Abwesenheitsbestätigung</option>
                            <option v-if="selectedAbsence && selectedAbsence.healthConfirmationFileId === null" value="CAREGIVER_HEALTH_DOC">Anwesenheitsbestätigung</option>
                        </select>
                    </div>

                    <input type="file" ref="fileInput" id="fileUpload" @change="handleFileChange" class="hidden" />

                    <div @drop.prevent="handleFileDrop" @dragover.prevent @click="onDropZoneClick" class="mt-4 p-4 border-dashed border-2 rounded-md text-center cursor-pointer">
                        Klicken Sie hier oder ziehen Sie Ihre Datei zum Hochladen
                    </div>
                </div>
                <div class="px-6 py-3 border-t border-gray-200 flex justify-end">
                    <button class="button mr-2" @click="closeUploadModal">Schließen</button>
                </div>
            </div>
        </div>

        <footer class="footer mt-8">
            <div class="footer-content text-center text-gray-500">
                <p>&copy; Alle Rechte vorbehalten.</p>
            </div>
        </footer>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'AbsencePage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            user: {},
            absencesChildren: [],
            absencesCaregiver: [],
            userAbsences: [],
            showAbsenceModal: false,
            showUploadModal: false,
            comment: '',
            absenceType: '',
            selectedChild: '',
            absenceReason: '',
            selectedAbsence: null,
            selectedDocumentType: '',
            file: null,
            children: [],
            errorMessage: '',
            absenceDateStart: '',
            absenceDateEnd: '',
            allDay: false,
            startTime: '06:00:00',
            endTime: '23:45:00',
            timeOptions: this.generateTimeOptions(),
            otpIntervalId: null,
        };
    },
    watch: {
        absenceType(newType) {
            if (newType === 'child') {
                this.absenceReason = '';
            }
        }
    },
    computed: {
        isValidForm() {
            if (this.absenceType === 'child') {
                return this.absenceDateStart && this.selectedChild;
            } else {
                const isDateValid = this.absenceDateStart && this.absenceDateEnd && (this.absenceDateStart <= this.absenceDateEnd);
                const isTimeValid = this.startTime && this.endTime && (this.startTime <= this.endTime);
                return isDateValid && isTimeValid && this.absenceReason; // Keine Validierung für comment
            }
        }
    },
    created() {
        this.initializeDashboard();
        this.otpIntervalId = setInterval(this.checkOTPStatus, 60000);
    },
    beforeUnmount() {
        if (this.otpIntervalId) {
            clearInterval(this.otpIntervalId);
        }
    },
    methods: {
        convertToHHMM(time) {
            const [hours, minutes] = time.split(":");
            return `${hours}:${minutes}`;
        },
        async checkOTPStatus() {
            try {
                const response = await axios.get(`/users/check-otp-status/${this.userId}`, { withCredentials: true });
                if (response.data && response.data.success === false) {
                    console.warn('OTP ist abgelaufen. Benutzer wird ausgeloggt.');
                    this.logoutAction();
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des OTP-Status:', error);
            }
        },
        setAllDayTimes() {
            console.log('Ganztägig aktiviert:', this.allDay);
            if (this.allDay) {
                this.startTime = '06:00:00';
                this.endTime = '23:45:00';
            } else {
                this.startTime = '06:00:00';
                this.endTime = '23:45:00';
            }
        },
        generateTimeOptions() {
            const times = [];
            let start = new Date();
            start.setHours(6, 0, 0);
            let end = new Date();
            end.setHours(24, 0, 0);

            while (start <= end) {
                times.push(this.formatTime(start));
                start.setMinutes(start.getMinutes() + 15);
            }

            return times;
        },
        formatTime(date) {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        },
        async initializeDashboard() {
            try {
                await this.getUserData();
            } catch (error) {
                console.error("Error during initialization:", error);
            }
        },
        async getUserData() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;

                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);

                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });
                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.combinedData || [];
                    this.absencesChildren = userDataResponse.data.absences_children || [];
                    this.absencesCaregiver = userDataResponse.data.absences_caregiver || [];
                    this.userAbsences = userDataResponse.data.user_absences || [];
                    this.children = this.combinedData.map(item => item.children_name);
                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                this.$router.push('/');
            }
        },
        getChildName(id) {
            const child = this.combinedData.find(item => item.fk_children_id === id);
            return child ? child.children_name : 'Unbekannt';
        },
        formatDate(dateStr) {
            return dateStr.slice(6, 8) + '.' + dateStr.slice(4, 6) + '.' + dateStr.slice(0, 4);
        },
        async reportAbsence() {
            try {
                if (!this.isValidForm) {
                    if (!this.absenceDateStart || !this.absenceDateEnd || this.absenceDateStart > this.absenceDateEnd) {
                        this.errorMessage = 'Das Startdatum darf nicht größer als das Enddatum sein.';
                    } else if (this.startTime > this.endTime) {
                        this.errorMessage = 'Die Startzeit darf nicht größer als die Endzeit sein.';
                    }
                    alert(this.errorMessage);
                    return;
                }

                const formattedStartDate = this.absenceDateStart.replace(/-/g, '');
                const formattedEndDate = this.absenceDateEnd.replace(/-/g, '');

                const payload = {
                    force_approve: false,
                    absence_start: formattedStartDate,
                    absence_type: this.absenceReason || 'Abwesend',
                    type: this.absenceType === 'child' ? 'CHILDREN' : 'CAREGIVER',
                    comment: this.comment || null
                };

                if (this.absenceType === 'child') {
                    const startDate = new Date(this.absenceDateStart);
                    const endDate = new Date(startDate);
                    endDate.setDate(startDate.getDate() + 14);

                    const formattedEndDateForChild = endDate.toISOString().slice(0, 10).replace(/-/g, ''); // Format as YYYYMMDD
                    payload.absence_end = formattedEndDateForChild;
                    payload.absence_time_start = '06:00:00';
                    payload.absence_time_end = '23:45:00';
                } else {
                    payload.absence_end = formattedEndDate;
                    payload.absence_time_start = this.startTime;
                    payload.absence_time_end = this.endTime;
                }

                let id;
                if (this.absenceType === 'child') {
                    const childRecord = this.combinedData.find(item => item.children_name === this.selectedChild);
                    if (childRecord && childRecord.fk_children_id) {
                        id = childRecord.fk_children_id;
                    } else {
                        console.error('Kein passender Kind-Datensatz gefunden.');
                        return;
                    }
                } else if (this.absenceType === 'caregiver') {
                    id = this.userId;
                }

                await axios.put(`/users/insert_absence/${id}`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                this.showAbsenceModal = false;
                await this.initializeDashboard();

                alert('Abwesenheit erfolgreich gemeldet!');

            } catch (error) {
                console.error('Fehler beim Melden der Abwesenheit:', error);
                alert('Fehler beim Melden der Abwesenheit. Bitte versuchen Sie es erneut.');
            }
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
        openUploadModal(absence) {
            // Überprüfen, ob beide Dokumente bereits hochgeladen wurden
            if (absence.sickConfirmationFileId !== null && absence.healthConfirmationFileId !== null) {
                alert("Beide Dokumente wurden bereits hochgeladen. Kein weiteres Hochladen erforderlich.");
                return;
            }

            this.selectedAbsence = absence;
            this.showUploadModal = true;

            if (absence.healthConfirmationFileId !== null && absence.sickConfirmationFileId !== null) {
                this.selectedDocumentType = '';
            } else if (absence.sickConfirmationFileId !== null && absence.healthConfirmationFileId === null) {
                this.selectedDocumentType = 'CAREGIVER_HEALTH_DOC';
            } else if (absence.sickConfirmationFileId === null && absence.healthConfirmationFileId === null) {
                this.selectedDocumentType = 'CAREGIVER_SICK_DOC';
            } else {
                this.selectedDocumentType = '';
            }
        },
        closeUploadModal() {
            this.showUploadModal = false;
            this.selectedDocumentType = '';
            this.file = null;
        },
        validateSelection() {
            if (this.selectedAbsence) {
                if (this.selectedAbsence.healthConfirmationFileId !== null || 
                    (this.selectedAbsence.sickConfirmationFileId !== null && this.selectedAbsence.healthConfirmationFileId !== null)) {
                    this.selectedDocumentType = '';
                } else if (this.selectedAbsence.sickConfirmationFileId !== null) {
                    this.selectedDocumentType = 'CAREGIVER_HEALTH_DOC';
                } else {
                    this.selectedDocumentType = 'CAREGIVER_SICK_DOC';
                }
            }
        },
        handleFileChange(event) {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Anwesenheitsbestätigung vorliegt.");
                return;
            }
            this.file = event.target.files[0];
            this.handleUpload();
        },
        handleFileDrop(event) {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Anwesenheitsbestätigung vorliegt.");
                return;
            }
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                this.file = files[0];
                this.handleUpload();
            }
        },
        onDropZoneClick() {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Genesungsbestätigung vorliegt.");
                return;
            }
            this.$refs.fileInput.click();
        },
        async handleUpload() {
            if (!this.file) {
                alert("Bitte wählen Sie eine Datei aus, bevor Sie versuchen, sie hochzuladen.");
                return;
            }

            const allowedTypes = [
                'image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp',
                'image/tiff', 'image/bmp', 'image/heic', 'image/heif', 'image/svg+xml',
                'image/x-icon', 'application/pdf'
            ];

            if (!allowedTypes.includes(this.file.type)) {
                alert('Ungültiger Dateityp. Bitte laden Sie nur Dateien der folgenden Formate hoch: JPEG, JPG, PNG, GIF, WEBP, TIFF, BMP, HEIC, HEIF, SVG, ICO, PDF.');
                return;
            }

            try {
                const formData = new FormData();
                formData.append('File', this.file); 

                const absenceId = this.selectedAbsence.id;
                const type = this.selectedDocumentType;

                if (!absenceId || !type) {
                    alert("Fehler: Abwesenheits-ID oder Dokumenttyp fehlt.");
                    return;
                }

                const uploadUrl = `/users/upload_documents/${absenceId}/${type}`;

                const response = await axios.post(uploadUrl, formData, {
                    withCredentials: true
                });

                if (response.data.success) {
                    alert('Dokument erfolgreich hochgeladen!');
                    this.closeUploadModal();
                    window.location.reload();
                } else {
                    alert('Fehler beim Hochladen: ' + response.data.message);
                }

            } catch (error) {
                console.error('Fehler beim Hochladen des Dokuments:', error);
                alert('Fehler beim Hochladen. Bitte versuchen Sie es erneut.');
            }
        }
    }
};
</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.content-container {
    @apply text-left mt-8 px-4 md:px-10;
}

.greeting-text {
    @apply text-center text-2xl font-bold mt-4;
    color: #1e8c4a;
}

.section-heading {
    @apply text-xl font-semibold text-gray-800;
    margin-botom: 12px;
}



.table-responsive {
    @apply mt-4;
}

.table {
    @apply w-full bg-white border border-gray-300 rounded-lg;
}

.table-cell {
    @apply px-4 py-2 border text-gray-700;
}

th {
    @apply bg-gray-50 font-medium text-gray-700;
}

@media (max-width: 768px) {
    .content-container {
        @apply px-4;
    }

    .greeting-text {
        @apply text-xl;
    }

    .section-heading {
        @apply text-lg;
    }

    .table-responsive {
        @apply mt-3;
    }

    .table-cell {
        @apply text-sm;
    }

    th {
        @apply text-sm;
    }
}

@media (max-width: 480px) {
    .greeting-text {
        @apply text-lg;
    }

    .section-heading {
        @apply text-base;
    }

    .table-cell {
        @apply text-xs;
    }

    th {
        @apply text-xs;
    }
}

.footer {
    padding: 1rem 0;
    border-top: 1px solid #e5e7eb;
}
.footer-content {
    font-size: 0.875rem;
}

.table-danger {
    background-color: #f89ea5 !important;
}
</style>