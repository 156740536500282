<template>
    <nav class="bg-white shadow-md">
      <div class="container mx-auto px-4">
        <div class="flex justify-center items-center py-4">
          <a class="text-2xl font-bold text-green-800 flex items-center" href="/dashboard">
            {{ APP_TITLE }}
          </a>
          <button @click="toggleMenu" class="lg:hidden text-gray-800 focus:outline-none ml-auto">
            <svg class="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <div :class="{ 'block': isMenuOpen, 'hidden': !isMenuOpen }" class="w-full lg:flex lg:items-center lg:w-auto bg-white lg:bg-transparent">
          <ul class="lg:flex lg:space-x-8 mt-4 lg:mt-0 space-y-4 lg:space-y-0 px-4 lg:px-0 text-center">
            <li>
              <a :class="getLinkClass('/dashboard')" href="/dashboard">
                <font-awesome-icon icon="clipboard-list" class="mr-2" /> Dienstplan
              </a>
            </li>
            <li>
              <a :class="getLinkClass('/bookings')" href="/bookings">
                <font-awesome-icon icon="calendar-check" class="mr-2" /> Arbeitsstunden
              </a>
            </li>
            <li>
              <a :class="getLinkClass('/absences')" href="/absences">
                <font-awesome-icon icon="user-slash" class="mr-2" /> Abwesenheiten
              </a>
            </li>
            <li>
              <a @click="logoutAction" class="text-lg text-gray-800 hover:text-green-800 transition-colors duration-300 block px-3 py-2 rounded-md flex items-center" href="#">
                <font-awesome-icon icon="sign-out-alt" class="mr-2" /> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <footer style="background-color: #f9f9f9; padding: 20px; text-align: center; font-size: 0.7em; color: #4a4a4a; border-top: 1px solid #ddd;">
        {{ VUE_APP_FOOTER_TEXT }}
      </footer>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBarComponent',
    data() {
      return {
        isMenuOpen: false,
        APP_TITLE: process.env.VUE_APP_TITLE,
        VUE_APP_FOOTER_TEXT: process.env.VUE_APP_FOOTER_TEXT,
        currentPath: window.location.pathname
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      logoutAction() {
        this.$emit('logout');
      },
      getLinkClass(path) {
        return this.currentPath === path
          ? 'text-lg text-green-800 font-semibold block px-3 py-2 rounded-md flex items-center text-xl lg:text-2xl'
          : 'text-lg text-gray-800 hover:text-green-800 transition-colors duration-300 block px-3 py-2 rounded-md flex items-center';
      }
    }
  };
  </script>
  
  <style>
  @media (max-width: 640px) {
    nav {
      font-size: 1.125rem;
    }
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    a {
      padding: 0.75rem 1rem;
    }
    .lg\:flex {
      display: block;
    }
    button {
      margin-left: auto;
    }
    .text-xl {
      font-size: 1.25rem;
    }
    .lg\:text-2xl {
      font-size: 1.5rem;
    }
  }
  ul {
    text-align: center;
  }
  </style>  