import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import './styles.css';

import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/pages/LoginPage';
import DashboardPage from './components/pages/DashboardPage';
import BookingPage from './components/pages/BookingPage';
import AbsencePage from './components/pages/AbsencePage';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHome, faCalendarCheck, faUserSlash, faSignOutAlt, faClipboardList } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faCalendarCheck, faUserSlash, faSignOutAlt, faClipboardList);


import { CalendarView } from 'vue-simple-calendar';
import 'bootstrap-icons/font/bootstrap-icons.css';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers['x-api-key'] = process.env.VUE_APP_API_KEY;
    return config;
});

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: LoginPage },
        { path: '/dashboard', component: DashboardPage },
        { path: '/bookings', component: BookingPage },
        { path: '/absences', component: AbsencePage },
    ],
});

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('CalendarView', CalendarView);

app.use(router).mount('#app');
