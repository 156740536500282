<template>
    <layout-div>
        <div class="content-container">
            <NavBarComponent @logout="logoutAction" />
        </div>

        <div class="content-container mt-6">
            <div class="legend">
                <div class="legend-item">
                    <span class="legend-color bg-green-300"></span>
                    <span>Bestätigte Stunden</span>
                </div>
                <div class="legend-item">
                    <span class="legend-color bg-red-300"></span>
                    <span>Nicht bestätigte Stunden</span>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="table-cell">Datum</th>
                            <th class="table-cell">Ist-Leistungsstunden</th>
                            <th class="table-cell">Soll-Leistungsstunden</th>
                            <th class="table-cell">Soll-Anmeldestunden</th>
                            <th class="table-cell">Pause (h)</th>
                            <th class="table-cell">Abw. Leistungsstunden</th>
                            <th class="table-cell">Abw. Anmeldestunden</th>
                            <th class="table-cell">Anmerkung</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredData" 
                            :key="item.planned_date" 
                            :class="item.admin_confirmed ? 'table-success' : 'table-danger'">
                            <td class="table-cell"><b>{{ formatBookingDate(item.planned_date) }}</b></td>
                            <td class="table-cell"><b>{{ item.overall_working_hours }}</b></td>
                            <td class="table-cell">{{ item.service_working_hours_per_day }}</td>
                            <td class="table-cell">{{ item.registered_working_hours_per_day }}</td>
                            <td class="table-cell">{{ item.sum_pause }}</td>
                            <td class="table-cell">{{ item.service_working_hours_per_day_diff }}</td>
                            <td class="table-cell">{{ item.registered_working_hours_per_day_diff }}</td>
                            <td class="table-cell">
                                {{ item.type === 'MANUAL_BOOKING_NORMAL' ? 'Normale Buchung' : (item.type === 'ADMIN_BOOKING' ? 'Admin-Buchung' : 'Automatische Buchung') }}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <footer class="footer mt-8">
            <div class="footer-content text-center text-gray-500">
                <p>&copy; Alle Rechte vorbehalten.</p>
            </div>
        </footer>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'DashboardPage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            user: {},
            combinedData: [],
            totalWorkingHours: 0,
            userId: null,
            otpIntervalId: null,
        };
    },
    computed: {
        filteredData() {
            return this.combinedData.filter(item =>
                ['MANUAL_BOOKING_NORMAL', 'ADMIN_BOOKING', 'AUTO_BOOKING_NORMAL'].includes(item.type)
            );
        },
    },
    created() {
        this.getUser();
        this.otpIntervalId = setInterval(this.checkOTPStatus, 60000);
    },
    beforeUnmount() {
        if (this.otpIntervalId) {
            clearInterval(this.otpIntervalId);
        }
    },
    methods: {
        async getUser() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;

                const userDataResponse = await axios.get(`/users/get_user_booking_data/${this.userId}`, { withCredentials: true });

                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.bookingData || [];
                    this.totalWorkingHours = this.combinedData.reduce((total, item) => total + parseFloat(item.overall_working_hours || 0), 0);
                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }
            } catch (error) {
                console.error('Error fetching combined data:', error);
                this.$router.push('/');
            }
        },
        async checkOTPStatus() {
            try {
                const response = await axios.get(`/users/check-otp-status/${this.userId}`, { withCredentials: true });
                if (response.data && response.data.success === false) {
                    console.warn('OTP ist abgelaufen. Benutzer wird ausgeloggt.');
                    this.logoutAction();
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des OTP-Status:', error);
            }
        },
        formatBookingDate(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr.slice(0, 4), dateStr.slice(4, 6) - 1, dateStr.slice(6, 8));
            if (isNaN(date.getTime())) return '';
            return ('0' + date.getDate()).slice(-2) + '.' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
                date.getFullYear();
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
    },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.content-container {
    @apply text-left mt-6 px-4 md:px-8 !important;
}

.greeting-text {
    @apply text-center text-2xl font-bold mt-4 !important;
    color: #1e8c4a !important;
}

.section-heading {
    @apply text-xl font-semibold text-gray-800 !important;
    margin-bottom: 10px !important;
}

.legend {
    @apply mb-4 flex space-x-4 items-center !important;
}

.legend-item {
    @apply flex items-center space-x-2 !important;
}

.legend-color {
    @apply block w-4 h-4 rounded-md !important;
}

.table-responsive {
    @apply mt-4 overflow-x-auto !important;
}

.table {
    @apply w-full bg-white border border-gray-200 rounded-md !important;
    min-width: 800px !important;
}

.table-cell {
    @apply px-3 py-2 border text-gray-700 !important;
    font-size: 0.875rem !important;
}

.table-success {
    background-color: #c9ecd1 !important;
}

.table-danger {
    background-color: #f89ea5 !important;
}

th {
    @apply bg-gray-50 font-medium text-gray-700 !important;
    font-size: 0.875rem !important;
}

@media (max-width: 768px) {
    .content-container {
        @apply px-4 !important;
    }

    .greeting-text {
        @apply text-xl !important;
    }

    .section-heading {
        @apply text-lg !important;
    }

    .table-responsive {
        @apply mt-3 !important;
    }

    .table-cell {
        @apply text-sm !important;
    }

    th {
        @apply text-sm !important;
    }
}

@media (max-width: 480px) {
    .greeting-text {
        @apply text-lg !important;
    }

    .section-heading {
        @apply text-base !important;
    }

    .table-cell {
        @apply text-xs !important;
    }

    th {
        @apply text-xs !important;
    }
}

.footer {
    padding: 1rem 0 !important;
    border-top: 1px solid #e5e7eb !important;
}
.footer-content {
    font-size: 0.875rem !important;
}
</style>

